import { getPublisherAssetList } from '@/api/rim/asset'
import { getStationByAssetId } from '@/api/rim/station'
import { getTaskType } from '@/api/msp/status'

export const indexMixins = {
  data () {
    return {
      statusArray: [
        { value: 0, name: '草稿', color: 'default' },
        { value: 1, name: '已提交', color: 'primary' },
        { value: 2, name: '申请中', color: 'warning' },
        { value: 3, name: '已申请', color: 'success' }
      ],
      pagesizeSetting: [15, 50, 100, 200, 500],
      publisherId: this.$store.getters.token.userInfo.publisherId
    }
  },
  methods: {
    /**
     * 获取当前登录用户的资产
     * @returns
     */
    async getAssetData () {
      const result = await getPublisherAssetList({ publisherId: this.publisherId })
      return result
    },
    /**
     * 获取指定资产id下的站点
     * @param {Number} assetId 资产id
     * @returns
     */
    async getStationData (assetId = null) {
      if (!assetId) {
        this.stationArray = []
        return
      }
      const result = await getStationByAssetId({ assetId })
      return result
    },
    /**
     * 获取任务类型集合
     * @returns
     */
    async getTaskTypeData () {
      const result = await getTaskType()
      return result
    }
    // statusFomartTag (status = 0) {
    //   let color = 'default'
    //   switch (status) {
    //     case 0:
    //       color = 'default'
    //       break
    //     case 1:
    //       color = 'default'
    //       break
    //     case 2:
    //       color = 'default'
    //       break
    //     case 3:
    //       color = 'default'
    //       break
    //     default:
    //       break
    //   }
    //   return color
    // }
  }
}
